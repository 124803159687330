namespace $ {
	export class $mol_keyboard_state extends $mol_plugin {
		
		/**
		 * ```tree
		 * event *
		 * 	^
		 * 	keydown? <=> down?
		 * 	keyup? <=> up?
		 * ```
		 */
		event() {
			return {
				...super.event(),
				keydown: (next?: any) => this.down(next),
				keyup: (next?: any) => this.up(next)
			} as Record< string, any >
		}
		
		/**
		 * ```tree
		 * key *
		 * ```
		 */
		key() {
			return {
			} as Record< string, any >
		}
		
		/**
		 * ```tree
		 * down? null
		 * ```
		 */
		@ $mol_mem
		down(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * up? null
		 * ```
		 */
		@ $mol_mem
		up(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
	}
	
}

