namespace $ {
	export class $hyoo_game_realm extends $mol_object2 {
		
		/**
		 * ```tree
		 * map \
		 * ```
		 */
		map() {
			return " "
		}
		
		/**
		 * ```tree
		 * map_rows /string[]
		 * ```
		 */
		map_rows() {
			return [
			] as readonly (string[])[]
		}
		
		/**
		 * ```tree
		 * map_width 0
		 * ```
		 */
		map_width() {
			return 0
		}
		
		/**
		 * ```tree
		 * map_height 0
		 * ```
		 */
		map_height() {
			return 0
		}
		
		/**
		 * ```tree
		 * spawn_pos /number
		 * 	0
		 * 	0
		 * ```
		 */
		spawn_pos() {
			return [
				0,
				0
			] as readonly number[]
		}
	}
	
}

