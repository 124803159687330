namespace $ {
	export class $hyoo_game_eye extends $mol_3d_pane {
		
		/**
		 * ```tree
		 * objects /$mol_3d_object
		 * ```
		 */
		objects() {
			return [
			] as readonly $mol_3d_object[]
		}
		
		/**
		 * ```tree
		 * Realm $hyoo_game_realm
		 * ```
		 */
		@ $mol_mem
		Realm() {
			const obj = new this.$.$hyoo_game_realm()
			
			return obj
		}
		
		/**
		 * ```tree
		 * pos? /number
		 * 	0
		 * 	0
		 * ```
		 */
		@ $mol_mem
		pos(next?: any) {
			if ( next !== undefined ) return next as never
			return [
				0,
				0
			] as readonly number[]
		}
		
		/**
		 * ```tree
		 * angle? 1
		 * ```
		 */
		@ $mol_mem
		angle(next?: any) {
			if ( next !== undefined ) return next as never
			return 1
		}
		
		/**
		 * ```tree
		 * wireframe false
		 * ```
		 */
		wireframe() {
			return false
		}
	}
	
}

