namespace $ {
	export class $hyoo_game_actor extends $mol_object2 {
		
		/**
		 * ```tree
		 * auto null
		 * ```
		 */
		auto() {
			return null as any
		}
		
		/**
		 * ```tree
		 * place_by_pos*
		 * ```
		 */
		place_by_pos(id: any) {
			return this.Realm().place_by_pos(id)
		}
		
		/**
		 * ```tree
		 * Realm $hyoo_game_realm place_by_pos* => place_by_pos*
		 * ```
		 */
		@ $mol_mem
		Realm() {
			const obj = new this.$.$hyoo_game_realm()
			
			return obj
		}
		
		/**
		 * ```tree
		 * pos? /number ^ pos_spawn
		 * ```
		 */
		@ $mol_mem
		pos(next?: any) {
			if ( next !== undefined ) return next as never
			return [
				...this.pos_spawn()
			] as readonly number[]
		}
		
		/**
		 * ```tree
		 * pos_spawn? /number
		 * 	0
		 * 	0
		 * ```
		 */
		@ $mol_mem
		pos_spawn(next?: any) {
			if ( next !== undefined ) return next as never
			return [
				0,
				0
			] as readonly number[]
		}
		
		/**
		 * ```tree
		 * angle? 0
		 * ```
		 */
		@ $mol_mem
		angle(next?: any) {
			if ( next !== undefined ) return next as never
			return 0
		}
		
		/**
		 * ```tree
		 * move_speed_track? 0
		 * ```
		 */
		@ $mol_mem
		move_speed_track(next?: any) {
			if ( next !== undefined ) return next as never
			return 0
		}
		
		/**
		 * ```tree
		 * move_speed_side? 0
		 * ```
		 */
		@ $mol_mem
		move_speed_side(next?: any) {
			if ( next !== undefined ) return next as never
			return 0
		}
		
		/**
		 * ```tree
		 * move_speed_track_max? 0.1
		 * ```
		 */
		@ $mol_mem
		move_speed_track_max(next?: any) {
			if ( next !== undefined ) return next as never
			return 0.1
		}
		
		/**
		 * ```tree
		 * move_speed_side_max? 0.1
		 * ```
		 */
		@ $mol_mem
		move_speed_side_max(next?: any) {
			if ( next !== undefined ) return next as never
			return 0.1
		}
		
		/**
		 * ```tree
		 * turn_speed? 0
		 * ```
		 */
		@ $mol_mem
		turn_speed(next?: any) {
			if ( next !== undefined ) return next as never
			return 0
		}
		
		/**
		 * ```tree
		 * turn_speed_max? 0.05
		 * ```
		 */
		@ $mol_mem
		turn_speed_max(next?: any) {
			if ( next !== undefined ) return next as never
			return 0.05
		}
		
		/**
		 * ```tree
		 * turn_left? false
		 * ```
		 */
		@ $mol_mem
		turn_left(next?: any) {
			if ( next !== undefined ) return next as never
			return false
		}
		
		/**
		 * ```tree
		 * turn_right? false
		 * ```
		 */
		@ $mol_mem
		turn_right(next?: any) {
			if ( next !== undefined ) return next as never
			return false
		}
		
		/**
		 * ```tree
		 * move_forward? false
		 * ```
		 */
		@ $mol_mem
		move_forward(next?: any) {
			if ( next !== undefined ) return next as never
			return false
		}
		
		/**
		 * ```tree
		 * move_backward? false
		 * ```
		 */
		@ $mol_mem
		move_backward(next?: any) {
			if ( next !== undefined ) return next as never
			return false
		}
		
		/**
		 * ```tree
		 * move_right? false
		 * ```
		 */
		@ $mol_mem
		move_right(next?: any) {
			if ( next !== undefined ) return next as never
			return false
		}
		
		/**
		 * ```tree
		 * move_left? false
		 * ```
		 */
		@ $mol_mem
		move_left(next?: any) {
			if ( next !== undefined ) return next as never
			return false
		}
	}
	
}

