namespace $ {
	export class $mol_3d_pane extends $mol_view {
		
		/**
		 * ```tree
		 * dom_name \canvas
		 * ```
		 */
		dom_name() {
			return "canvas"
		}
		
		/**
		 * ```tree
		 * context $mol_3d_context / <= context_native
		 * ```
		 */
		@ $mol_mem
		context() {
			const obj = new this.$.$mol_3d_context(
				this.context_native()
			)
			
			return obj
		}
		
		/**
		 * ```tree
		 * field *
		 * 	^
		 * 	width <= width
		 * 	height <= height
		 * ```
		 */
		field() {
			return {
				...super.field(),
				width: this.width(),
				height: this.height()
			} as Record< string, any >
		}
		
		/**
		 * ```tree
		 * paint null
		 * ```
		 */
		paint() {
			return null as any
		}
		
		/**
		 * ```tree
		 * context_native WebGL2RenderingContext
		 * ```
		 */
		@ $mol_mem
		context_native() {
			const obj = new this.$.WebGL2RenderingContext()
			
			return obj
		}
		
		/**
		 * ```tree
		 * width 0
		 * ```
		 */
		width() {
			return 0
		}
		
		/**
		 * ```tree
		 * height 0
		 * ```
		 */
		height() {
			return 0
		}
	}
	
}

