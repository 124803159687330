namespace $.$$ {
	
	$mol_style_define( $mol_3d_pane, {
			
		alignSelf: 'stretch',
		justifySelf: 'stretch',
		
		flex: {
			grow: 1,
			shrink: 1,
		},
		
	} )
	
}
